<template>
  <div id="app">
    <!-- <div class="custom-status" :style="{height:(isIos()?'30px':'0')}"></div> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import commonUtils from "@/utils/commonUtils.js";
export default {
  methods: {
    // isIos() {
    //   if (commonUtils.appSource() === "ios") {
    //     return true;
    //   }
    //   return false;
    // }
  }
};
</script>
<style lang="less">
html {
  height: 100%;
  body {
    height: 100%;
    #app {
      height: 100%;
      .page {
        height: 100%;
        background-color: #f7f8fa;
        display: flex;
        flex-direction: column;
        .page-header {
          background: #009efa;
        }
        .page-body {
          flex: 1;
          overflow-y: auto;
        }
        .page-footer {
          
        }
      }
    }
  }
}



// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
//   padding-top: constant(safe-area-inset-top);
//   padding-top: env(safe-area-inset-top);
// }

// .content,
// .van-pull-refresh {
//   height: 100%;
// }

// #nav {
//   padding: 30px;
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
