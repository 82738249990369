import './set-public-path';
import 'amfe-flexible';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import singleSpaVue from 'single-spa-vue';

// import vConsole from '@/assets/js/vconsole.js';

import Vant, { Lazyload, Toast } from 'vant';
import 'vant/lib/index.css';

// import './mock';
import qs from 'qs';
import BaseNavBar from './components/BaseNavBar.vue';

import './assets/css/base.less';

// Vue.use(vConsole);
Vue.use(Vant);
Vue.use(Lazyload);

Vue.component('BaseNavBar', BaseNavBar);

Vue.prototype.qs = qs;
Vue.prototype.toast = Toast;

Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    store
  }
});

console.log('process.env.VUE_APP_ENV :>> ', process.env.VUE_APP_ENV);
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
