import Vue from 'vue';
import VueRouter from 'vue-router';
// import Index from '../views/home/Index.vue';
import { ilsPostRequest } from "@/api/request/request.js";
Vue.use(VueRouter);
let routes = [
  // {
  //   path: '/',
  //   component: Index,
  //   meta: {
  //     keepAlive: true, // 需要缓存
  //     isBack: false // 用来判断是否是返回上一页
  //   }
  // }
];

// 加载当前文件夹内路由配置
const routerContext = require.context('./', true, /\.js$/);
routerContext.keys().forEach(route => {
  // 过滤当前index.js,不处理
  if (route.startsWith('./index')) {
    return;
  }
  const routerModule = routerContext(route);
  // 兼容 import export 和 require module.export 两种规范
  routes = routes.concat(routerModule.default || routerModule);
  console.log('routes-->', routes);
});

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
});

// 路由拦截器
router.beforeEach((to, from, next) => {
  next();
});


export default router;
