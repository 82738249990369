// import OrderList from '../views/pages/OrderList';
// import OrderDetail from '../views/pages/OrderDetail';
// import OrderResult from '../views/pages/OrderResult';
// import MyOrderList from '../views/pages/MyOrderList';
// import MyOrderDetail from '../views/pages/MyOrderDetail';
// import("@/components/HelloWorld")
export default [
  {
    path: '/washcar-module/OrderList',
    name: 'OrderList',
    component: () => import('../views/pages/OrderList')
  }, {
    path: '/washcar-module/OrderDetail',
    name: 'OrderDetail',
    component: () => import('../views/pages/OrderDetail')
  }, {
    path: '/washcar-module/OrderResult',
    name: 'OrderResult',
    component: () => import('../views/pages/OrderResult')
  }, {
    path: '/washcar-module/MyOrderList',
    name: 'MyOrderList',
    component: () => import('../views/pages/MyOrderList')
  }, {
    path: '/washcar-module/MyOrderDetail',
    name: 'MyOrderDetail',
    component: () => import('../views/pages/MyOrderDetail')
  }, {
    path: '/washcar-module/OrderEdit',
    name: 'OrderEdit',
    component: () => import('../views/pages/OrderEdit')
  }
];
