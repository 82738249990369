export default {
    getTransId() {
        var date = new Date();
        var year = date.getFullYear().toString();
        var month = (date.getMonth() + 1).toString();
        var day = date.getDate().toString();
        var hours = date.getHours().toString();
        var minutes = date.getMinutes().toString();
        var seconds = date.getSeconds().toString();
        var random = Math.floor((Math.random()*999999)+1);
        return year + month + day + hours + minutes + seconds + random;
    },
    appSource() {
        const u = navigator.userAgent.toLocaleLowerCase();
        // console.log('设备类型  u：', u);
        if (u.indexOf('android') != -1) {
          return 'andriod';
        } else if (u.indexOf('iphone') != -1) {
          return 'ios';
        }
        return 'other';
    }
};