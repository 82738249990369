 <template>
  <div class="navBar">
    <van-nav-bar
      :title="title"
      fixed
      @click-left="onClickLeft"
      @click-right="onClickRight"
      :style="{paddingTop:(isIos()?'30px':'0')}"
    >
      <template v-if="$slots.left" slot="left">
        <slot name="left"></slot>
      </template>
      <template v-else-if="isBack" slot="left">
        <van-icon name="arrow-left" size="18" :color="backIconColor" />
      </template>
      <template slot="right">
        <slot name="right"></slot>
      </template>
    </van-nav-bar>
    <div class="nav-top-placeholder" v-if="isPlaceholder" :style="{paddingTop:(isIos()?'30px':'0')}"></div>
  </div>
</template>
<script>
import commonUtils from "@/utils/commonUtils.js";
export default {
  props: {
    // 是否显示返回按钮，默认为true，优先级低于slot
    isBack: { type: [Boolean, String], default: true },
    // 默认返回按钮颜色
    backIconColor: { type: String, default: "black" },
    // 标题
    title: { type: String, default: "" },
    // 固定在顶部时，是否在标签位置生成一个等高的占位元素
    isPlaceholder: { type: Boolean, default: false }
  },
  data() {
    return {};
  },
  methods: {
    isIos() {
      if (commonUtils.appSource() === "ios") {
        return true;
      }
      return false;
    },
    onClickLeft() {
      console.log("BaseNavBar  onClickLeft");
      this.$emit("onClickLeft");
    },
    onClickRight() {
      console.log("BaseNavBar  onClickRight");
      this.$emit("onClickRight");
    }
  }
};
</script>
<style>
.van-nav-bar {
  background: white;
  /* background: #38bc9d; */
}
.van-nav-bar__content {
  height: 1.226667rem;
}
.van-nav-bar .van-icon {
  color: black;
}

.van-nav-bar__title {
  color: black;
  /* color: white; */
}

.nav-top-placeholder {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  height: 1.173333rem;
}
</style>
